@import "colors";
@import "grid";

$font-family-sans-serif: 'Inter', -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, "Noto Sans", sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji";
$font-family-serif: 'serif';
$font-family-blocks: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, "Noto Sans", sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji";
$font-family-blocks-print: "SF Pro Display", -apple-system, BlinkMacSystemFont;

$body-color: $gray-800;
$body-bg:    $gray-100;
$light:      $gray-150;

$link-color: inherit;
$link-hover-color: inherit;
$link-hover-decoration: none;

$border-radius: 2px;
$border-radius-sm: 2px;
$border-radius-lg: 3px;

$dropdown-link-active-color: $gray-900;
$dropdown-link-active-bg: $gray-200;
$dropdown-link-hover-bg: $gray-100; // boostrap changed this in 4.6.0

$modal-header-padding-y: .8125rem;
$modal-lg2: 900px;
$modal-xxl: 1600px;

$box-shadow-sm: 0px 1px 3px 1px rgba($black, .075);

$form-feedback-valid-color: $gray-400;
$form-feedback-icon-valid-color: $green;

$input-btn-focus-width: 4px;
$input-focus-width:     4px;

@import "../../../../node_modules/bootstrap/scss/functions";
@import "../../../../node_modules/bootstrap/scss/variables";
@import "../../../../node_modules/bootstrap/scss/mixins";

$spacers: map-merge(
    (6: ($spacer * 4.5)), $spacers
);

$nav-top-height:    60px;
$subnav-top-height: 48px;

$yiq-contrasted-threshold: 200;

$danger-light: $light-red;
$theme-colors: map-merge(
    $theme-colors,
    (
      "danger-light": $danger-light,
      "lark": $gray-400,
      "lighter": $gray-100
    )
);

/// INTERMEDIATE-COLORS

$border-color: $gray-300;
$brand-color: $dark-blue;
$drop-blocks-border-color: $border-color;

/// INPUTS
$custom-control-indicator-border-color: $gray-400;
$input-placeholder-color:               $gray-400;

/// CARDS

$card-header-color: $brand-color;
$card-background-color: $white;
$card-border-color: $border-color;
$card-border-radius: $border-radius-lg;
$card-border-width: 1px;

/// EMBED

$embed-responsive-aspect-ratios: (
    (4, 1),
    (21, 9),
    (16, 9),
    (16, 10),
    (4, 3),
    (5, 4),
    (1, 1)
);

/// CONTROLS

/// - GENERAL

$controls-font-size-xs: 14px;
$controls-font-size-sm: 14px;
$controls-font-size-md: 14px;
$controls-font-size-lg: 15px;
$controls-font-size-lxl: 16px;
$controls-font-size-xl: 18px;


/// - FIELDSET

$controls-fieldset-font-size-xl: $controls-font-size-lg;
$controls-fieldset-font-size-lg: $controls-font-size-md;

$controls-fieldset-divider-color: $border-color;
$controls-fieldset-divider-width: 1px;

$controls-fieldset-padding: 20px;
$controls-fieldset-header-padding: 10px;

$controls-fieldset-header-caret-color: $gray-300;

/// - FORMGROUP

$controls-formgroup-margin-bottom: 16px;

/// - SWITCH

$controls-switch-height: 16px;
$controls-switch-width: 28px;
$controls-switch-padding: 3px;
$controls-switch-dot-size: $controls-switch-height - $controls-switch-padding * 2;
$controls-switch-transform: $controls-switch-width - $controls-switch-dot-size - $controls-switch-padding * 2;
$controls-switch-border-radius: $controls-switch-height * 0.5;

$controls-switch-border-width: 2px;
$controls-switch-border-color: $gray-400;
$controls-switch-active-border-color: #0083F6;

$controls-switch-background-color: #fff;
$controls-switch-active-background-color: #0083F6;

/// - COLOR

$controls-color-dropdown-width: 230px;

/// - OPTION-BUTTONS

$controls-option-buttons-gap: map_get($spacers, 2);

